import CreditHistory from '../../credit-history/index.vue';
import BuyCredits from '../../credit/order/index.vue';
import ConfigCredits from '../../credit-price/index.vue';
import OrderList from '../../credit-order-history/index.vue';
import DealersSubdealers from '../../credit/index.vue'
import CreditAdminHistory from '../../credit-history-admin/index.vue';
import CreditHistoryReceived from '../../credit-history/CreditHistoryReceived.vue';
import Vue from 'vue';
import {mapState} from "vuex";

const menuOptions = [
  {text: 'Buy Credits', value: 'buy-credits'}, // 0
  {text: 'Credit Overview', value: 'credits-overview'},  // 1
  {text: 'Payments', value: 'payments'},  // 2
  {text: 'Accounts', value: 'dealers'}, // 3
  {text: 'Configuration', value: 'config'}, // 4
]

export default Vue.extend({
  name: 'CreditsPurchase',
  components: {
    CreditHistory,
    BuyCredits,
    ConfigCredits,
    OrderList,
    DealersSubdealers,
    CreditAdminHistory,
    CreditHistoryReceived
  },
  data: () => ({
    showMovements: true,
    showOrders: false,
    showDealers: false,
    historySize: 20,
    btnSelected: 'credits-overview',
    btnOptions: [],
  }),
  computed: {
    ...mapState('buySale', ['information'])
  },
  async mounted() {
    this.assignMenu();
    if (this.$route.query.tab === 'buy')
      this.btnSelected = 'buy-credits'
  },
  watch: {
    information: {
      handler: function () {
        this.btnOptions = [];
        this.assignMenu();
      },
      deep: true
    }
  },
  methods: {
    assignMenu() {
      if (this.$userCan(['distributor', 'dealer', 'subdealer'])) {
        const per = this.$userPermission(true,'purchase', 'purchase')
        const subPer = this.$checkSubUserPermission( 'credits.purchase_credits.buy_credits')
        this.btnOptions.push(menuOptions[0], menuOptions[2])
        this.btnSelected = 'payments'
        if (this.information.haveChildren === false) {
          this.btnOptions.push(menuOptions[1])
        }
        if(!per || !subPer){
          this.removeItem('buy-credits')
        }
      } else if (this.$userCan(['administrator'])) {
        this.btnOptions = menuOptions
        this.removeItem('buy-credits')
      }
    },

    removeItem(value) {
      for (let i = 0; i < this.btnOptions.length; i++) {
        if (this.btnOptions[i].value.toString() === value.toString()) {
          this.btnOptions.splice(i, 1)
        }
      }
    },
  },
});
