<template>
  <div style="width: 100%; margin-top: 0.5vw">
    <b-nav fill v-if="information.haveChildren && $userCan(['distributor', 'dealer'])">
      <b-nav-item active v-if="$userPermission(true,'purchase', 'purchase')  && $checkSubUserPermission('credits.purchase_credits') " >
        <span class="title" :class="{ 'selected': showPurchase }" @click="selectTab('purchase')">Purchase</span>
      </b-nav-item>
      <b-nav-item v-if="$userPermission(true,'sale', 'sale')  && $checkSubUserPermission('credits.sale_credits') ">
        <span class="title" :class="{ 'selected': showSale }" @click="selectTab('sale')">Sale</span>
      </b-nav-item>
    </b-nav>

    <purchase v-if="
    (!information.haveChildren || showPurchase)
    && $userCan(['distributor', 'dealer','subdealer'])
    && $checkSubUserPermission('credits.purchase_credits')"/>

    <sale v-if="(showSale
    && information.haveChildren) || $userCan(['administrator'])
    && $userPermission(true,'sale', 'sale')
    && $checkSubUserPermission('credits.sale_credits')"/>

    <CreditHistory style="width: 100%;" v-if=" (showOverview && information.haveChildren === false)"/>
    <CreditAdminHistory v-if="$userCan(['support'])"/>

  </div>
</template>

<script>

import Purchase from '@/views/dashboard/credit-dashboard/purchase/index.vue';
import Sale from '@/views/dashboard/credit-dashboard/sale/index.vue';
import {mapState} from 'vuex';
import CreditHistory from '@/views/dashboard/credit-history/index.vue';
import CreditAdminHistory from '@/views/dashboard/credit-history-admin/index.vue';
import {checkSubUserPermission} from "@/utils/prototypes";

export default {
  name: 'DashboardCredits',
  components: {
    Purchase,
    Sale,
    CreditHistory,
    CreditAdminHistory,
  },
  computed: {
    ...mapState('buySale', ['information']),
  },
  mounted() {
    if (!checkSubUserPermission('credits.sale_credits')){
      this.showSale = false
      this.showPurchase = true
    }
  },
  data: () => ({
    showSale: true,
    showPurchase: false,
    showOverview: false
  }),
  methods: {
    selectTab(type) {
      if (type.toString() === 'purchase') {
        this.showSale = false;
        this.showOverview = false;
        this.showPurchase = true;
      } else if (type.toString() === 'sale') {
        this.showSale = true;
        this.showOverview = false;
        this.showPurchase = false;
      } else if (type.toString() === 'overview') {
        this.showSale = false;
        this.showOverview = true;
        this.showPurchase = false;
      }
    }
  }
};
</script>

<style scoped>

.selected {
  background: black;
  padding: 0.3vw;
  color: white !important;
  border: 0 !important;
}

.title {
  border: 1px solid red;
  padding: 0.5vw;
  color: #d14343;
  font-weight: bold;
  font-size: 1.3rem;
}

</style>
