import CreditHistory from '../../credit-history/index.vue';
import BuyCredits from '../../credit/order/index.vue';
import ConfigCredits from '../../credit-price/index.vue';
import OrderList from '../../credit-order-history/index.vue';
import DealersSubdealers from '../../credit/index.vue'
import CreditAdminHistory from '../../credit-history-admin/index.vue';
import CreditHistoryReceived from '../../credit-history/CreditHistoryReceived.vue';
import Vue from 'vue';
import {mapState} from "vuex";

const menuOptions = [
  {text: 'Payments', value: 'payments'},
  {text: 'Credit Overview', value: 'overview'},
  {text: 'Accounts', value: 'accounts'},
  {text: 'Configuration', value: 'config'},
]

export default Vue.extend({
  name: 'CreditsSale',
  components: {
    CreditHistory,
    BuyCredits,
    ConfigCredits,
    OrderList,
    DealersSubdealers,
    CreditAdminHistory,
    CreditHistoryReceived
  },
  data: () => ({
    showMovements: true,
    showOrders: false,
    showDealers: false,
    historySize: 20,
    btnSelected: 'accounts',
    btnOptions: [],
  }),
  computed: {
    ...mapState('buySale', ['information']),
    ...mapState('geo', ['showInLocal'])
  },
  async mounted() {
    if (this.$userCan(['distributor', 'dealer']) && this.information.haveChildren) {
      const per = this.$userPermission(false, 'sale', 'configuration')
      const perSub = this.$checkSubUserPermission('credits.sale_credits.configuration')
      if (!per || !perSub) {
        this.btnOptions = menuOptions;
        this.removeItem('config')
      } else {
        this.btnOptions = menuOptions;
      }
      this.btnSelected = 'accounts'
    } else if (this.$userCan(['administrator'])) {
      this.btnOptions = menuOptions;
      this.btnSelected = 'accounts'
    } else if (this.$userCan(['subdealer'])) {
      this.btnOptions = menuOptions;
      this.btnSelected = 'overview'
    }
    if (this.$route.query.tab === 'buy')
      this.btnSelected = 'buy-credits'
    if (this.$route.query.tab === 'company-empty')
      this.btnSelected = 'config'
  },
  methods: {
    removeItem(value) {
      for (let i = 0; i < this.btnOptions.length; i++) {
        if (this.btnOptions[i].value.toString() === value.toString()) {
          this.btnOptions.splice(i, 1)
        }
      }
    }
  },
});
